import React, { useEffect, useState } from "react";
import Banner from "./components/Banner";
import Search from "./components/Search";
import CourseCard from "components/card/CourseCard";
import AvatarSimmmple from "assets/img/avatars/avatarSimmmple.png";
import industry from "assets/img/course-content/3d Printing industry.jpg";
import technologies from "assets/img/course-content/technologies.jpg";
import slicing from "assets/img/course-content/slicing.jpg";
import design from "assets/img/course-content/Desigining.jpg";
import mechCubes from "assets/img/course-content/MechCubes.jpg";
import assistiveB from "assets/img/course-content/AssisstiveB.jpg";
import microbit from "assets/img/course-content/Microbit.jpg";
import assistiveC from "assets/img/course-content/AssistiveC.jpg";
import typography from "assets/img/course-content/Typography.jpg";
import rethinkP from "assets/img/course-content/Rethinkplastic.jpg";
import functionalW from "assets/img/course-content/FunctionalWrench.jpg";
import selfW from "assets/img/course-content/SelfWateringPLants.jpg";
import suspB from "assets/img/course-content/SuspensionBridge.jpg";
import personalizedG from "assets/img/course-content/personalizedGift.jpg";
import IntroToT from "assets/img/course-content/IntroToT.jpg";
import AutoToF from "assets/img/course-content/AutoToF.jpg";
import IntroToF from "assets/img/course-content/IntrotoF.jpg";

const NftProfile = () => {
  const [courses, setCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("lowToHigh")
  const [section, setSection] = useState("miniCourses");

  useEffect(() => {
    // Load the static course content
    const fetchCourses = () => {
      const courseData = [
        {
          number: 1,
          title: 'The 3D Printing Industry',
          description: 'Placeholder description for The 3D Printing Industry.',
          date: '1 Jan 2024',
          tags: ['Industry Insights'],
          bgImage: industry,
          href: 'https://weareprintlab.com/s/sgdnwg/',
        },
        {
          number: 2,
          title: '3D Printing Technologies',
          description: 'Placeholder description for 3D Printing Technologies.',
          date: '2 Jan 2024',
          tags: ['Emerging Tech'],
          bgImage: technologies,
          href: 'https://weareprintlab.com/s/mmp7q7/',
        },
        {
          number: 3,
          title: 'Slicing for 3D Printing',
          description: 'Placeholder description for Slicing for 3D Printing.',
          date: '3 Jan 2024',
          tags: ['Print Optimization'],
          bgImage: slicing,
          href: 'https://weareprintlab.com/s/shwlwv/',
        },
        {
          number: 4,
          title: 'Intro to 3D CAD with Tinkercad',
          description: 'Placeholder description for Intro to 3D CAD with Tinkercad.',
          date: '4 Jan 2024',
          tags: ['Tinkercad Tools'],
          bgImage: IntroToT,
          href: 'https://weareprintlab.com/s/dwlwvp/',
        },
        {
          number: 5,
          title: 'Tinkercad to Fusion',
          description: 'Placeholder description for Tinkercad to Fusion.',
          date: '5 Jan 2024',
          tags: ['Design Workflow'],
          bgImage: AutoToF,
          href: 'https://weareprintlab.com/s/eap2qa/',
        },
        {
          number: 6,
          title: 'Intro to 3D CAD with Fusion',
          description: 'Placeholder description for Intro to 3D CAD with Fusion.',
          date: '6 Jan 2024',
          tags: ['Advanced CAD'],
          bgImage: IntroToF,
          href: 'https://weareprintlab.com/s/vhuenf/',
        },
        {
          number: 7,
          title: 'Designing for 3D Printing',
          description: 'Placeholder description for Designing for 3D Printing.',
          date: '7 Jan 2024',
          tags: ['Creative Workflow'],
          bgImage: design,
          href: 'https://weareprintlab.com/s/zuaf6s/',
        },
        {
          number: 8,
          title: 'Design Methods',
          description: 'Placeholder description for Design Methods.',
          date: '8 Jan 2024',
          tags: ['Design Theory'],
          bgImage: 'https://codetheweb.blog/assets/img/posts/basic-types-of-html-tags/cover.jpg',
          href: 'https://weareprintlab.com/s/rvge60/',
        },
        {
          number: 9,
          title: 'Mechanism Cubes',
          description: 'Placeholder description for Mechanism Cubes.',
          date: '9 Jan 2024',
          tags: ['Interactive Models'],
          bgImage: mechCubes,
          href: 'https://weareprintlab.com/s/5wsuze/',
        },
        {
          number: 10,
          title: 'Assistive Bottle Openers',
          description: 'Placeholder description for Assistive Bottle Openers.',
          date: '10 Jan 2024',
          tags: ['Accessibility Tools'],
          bgImage: assistiveB,
          href: 'https://weareprintlab.com/s/hkv8at/',
        },
        {
          number: 11,
          title: 'Micro:bit Switch Access',
          description: 'Placeholder description for Micro:bit Switch Access.',
          date: '11 Jan 2024',
          tags: ['Electronics Integration'],
          bgImage: microbit,
          href: 'https://weareprintlab.com/s/xlx6ju/',
        },
        {
          number: 12,
          title: 'Assistive Clips',
          description: 'Placeholder description for Assistive Clips.',
          date: '12 Jan 2024',
          tags: ['Custom Accessories'],
          bgImage: assistiveC,
          href: 'https://weareprintlab.com/s/2ygxaa/',
        },
        {
          number: 13,
          title: 'Personalized Gifts',
          description: 'Placeholder description for Personalized Links.',
          date: '13 Jan 2024',
          tags: ['Gift Ideas'],
          bgImage: personalizedG,
          href: 'https://weareprintlab.com/s/ammh7w/',
        },
        {
          number: 14,
          title: '3D Printed Typography',
          description: 'Placeholder description for 3D Printed Typography.',
          date: '14 Jan 2024',
          tags: ['Letter Art'],
          bgImage: typography,
          href: 'https://weareprintlab.com/s/eertq8/',
        },
        {
          number: 15,
          title: 'Rethinking Plastics',
          description: 'Placeholder description for Rethinking Plastics.',
          date: '15 Jan 2024',
          tags: ['Eco-Friendly Printing'],
          bgImage: rethinkP,
          href: 'https://weareprintlab.com/s/k6nkxs/',
        },
        {
          number: 16,
          title: 'Functional Wrenches',
          description: 'Placeholder description for Functional Wrenches.',
          date: '16 Jan 2024',
          tags: ['Tool Design'],
          bgImage: functionalW,
          href: 'https://weareprintlab.com/s/ycyns8/',
        },
        {
          number: 17,
          title: 'Self Watering Planters',
          description: 'Placeholder description for Self Watering Planters.',
          date: '17 Jan 2024',
          tags: ['Innovative Planters'],
          bgImage: selfW,
          href: 'https://weareprintlab.com/s/kjlagw/',
        },
        {
          number: 18,
          title: 'Suspension Bridge',
          description: 'Placeholder description for Suspension Bridge.',
          date: '18 Jan 2024',
          tags: ['Engineering Models'],
          bgImage: suspB,
          href: 'https://weareprintlab.com/s/e3cwzm/',
        },
        // Additional courses...
      ];
      setCourses(courseData);
    };

    fetchCourses();
  }, []);

  const handleToggle = (newSection) => setSection(newSection);

  const handleSearch = (query) => setSearchQuery(query);

  const handleSortChange = (order) => setSortOrder(order);

  const filteredCourses = courses
    .filter((course) => {
      const matchesSection = section === "miniCourses" ? course.number < 9 : course.number >= 9;
      const matchesSearch = course.title.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesSection && matchesSearch;
    })
    .sort((a, b) => {
      if (sortOrder === "lowToHigh") return a.number - b.number;
      if (sortOrder === "highToLow") return b.number - a.number;
      return 0;
    });

  const counts = {
    miniCourses: courses.filter((course) => course.number < 9).length,
    projects: courses.filter((course) => course.number >= 9).length,
  };

  return (
    <div className="mt-3 h-full w-full rounded-[20px]">
      {/* Banner Section */}
      <Banner
        image={AvatarSimmmple}
        profile={AvatarSimmmple}
        wallet="3D Printing Course Hub"
        address="Explore and Learn"
        name="3D Printing"
        counts={counts}
        onToggle={handleToggle}
      />
      {/* Search Bar */}
      <div className="mt-7 w-full">
      <Search
          onSearch={handleSearch}
          onSortChange={handleSortChange}
        />
      </div>

      {/* Course Results */}
      <div className="mt-3">
        <h4 className="mt-[23px] ml-[23px] text-2xl font-bold text-navy-700 dark:text-white">
          {filteredCourses.length} Results
        </h4>

        <div className="mt-[64px] grid grid-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-4">
          {filteredCourses.map((course) => (
            <CourseCard
              key={course.number}
              title={course.title}
              author={course.tags}
              description={course.description}
              image={course.bgImage}
              href={course.href}
              tags={course.tags}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NftProfile;
