import { useParams, useLocation } from "react-router-dom";
import React from "react";
import Details from "./components/Details";
import OrderStatus from "./components/OrderStatus";
import Receipt from "./components/Receipt";
import { fetchBillingsByUserId } from "api"; 
import { useQuery } from "@tanstack/react-query";


const OrderDetails = () => {

  const { orderId } = useParams();
  const location = useLocation();
  const userId = location.state?.userId;
  

  // Fetch billing data using userId from the user profile query
  const { data: billingDataArray, isLoading, isError } = useQuery({
    queryKey: ["billingData", userId, orderId],
    queryFn: () => fetchBillingsByUserId(userId, orderId),
    enabled: !!userId && !!orderId, // Only runs this query if userId is available
    onError: (error) => {
      console.error("Error fetching billing data:", error);
    },
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError || !billingDataArray || billingDataArray.length === 0) return <div>Error loading billing data.</div>;

  // Use the first item if `billingDataArray` is an array, assuming it’s not empty
  const billingData = Array.isArray(billingDataArray) ? billingDataArray[0] : billingDataArray;

  console.log("Billing Data:", billingData);

  return (
    <div className="mt-3 grid h-full w-full grid-cols-7 gap-[20px] rounded-[20px]">
      <div className="z-0 col-span-7 lg:col-span-5">
        <Receipt billingData={billingData} />
      </div>
      <div className="z-0 col-span-7 lg:col-span-2">
        <OrderStatus billingData={billingData} />
      </div>
      <div className="z-0 col-span-7 lg:col-span-7">
        <Details billingData={billingData} />
      </div>
    </div>
  );
};

export default OrderDetails;

