import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import LandingPage from "views/landing";
import Settings from "views/admin/main/account/settings";
import "assets/css/Plugins.css";
import { useState, useEffect } from "react";
import ProductNew from "views/admin/main/ecommerce/newProject";
import { AuthProvider } from "contexts/authContext";
import ProtectedRoute from "contexts/ProtectedRoute";
import OthersError from "views/admin/main/others/404";
import Access from "views/access"; 
import MentorHub from "views/admin/dashboards/mentorHub";
import { Permissions } from "utils/permissions";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const App = () => {
  const [themeApp, setThemeApp] = useState({
    "--background-100": "#FFFFFF",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#E9E3FF",
    "--color-100": "#C0B8FE",
    "--color-200": "#A195FD",
    "--color-300": "#8171FC",
    "--color-400": "#7551FF",
    "--color-500": "#FF6000",
    "--color-600": "#3311DB",
    "--color-700": "#2111A5",
    "--color-800": "#190793",
    "--color-900": "#11047A",
  });

  const [mini, setMini] = useState(false);

  const queryClient = new QueryClient();

  useEffect(() => {
    for (const color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
  }, [themeApp]);

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}> 
      <Routes>
        {/* Public Routes */}
        <Route path="/landing" element={<LandingPage />} />
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="*" element={<OthersError />} /> {/* 404 Page Route */}
        
        <Route 
          path="/course"
          element={
            <ProtectedRoute  element={<Access />} requiresSubscription={true} courseId={1} />
          }
        />

        {/* General Admin Routes */}
        <Route
          path="admin/*"
          element={
            <ProtectedRoute
              element={
                <AdminLayout setMini={setMini} mini={mini} theme={themeApp} setTheme={setThemeApp} />
              }
            />
          }
        >
          {/* General routes accessible to all authenticated users */}
          <Route path="main/ecommerce/new-project" element={<ProductNew />} />
          <Route path="main/account/settings" element={<Settings />} />


        </Route>

        
        
        {/* Redirect Root to Admin */}
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;
