import { useState } from "react";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import React from "react";
import Checkbox from "components/checkbox";
import { useNavigate, Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import DropdownForForm from "components/dropdown/dropdownForForm";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function SignUpDefault() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: '',
    city: '',      
    country: ''     
  });
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate();

  // Handle input field changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({ ...prevState, [id]: value }));
    
    // Clear the error message for the specific field
    setErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
  };

  // Function to handle form submission and prevent sending data if errors exist
  const handleSignUp = async () => {
    setErrors({}); 
    let formErrors = {};

    const nameRegex = /^[A-Za-z\s-]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const trimmedFormData = {
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      username: formData.username.trim(),
      email: formData.email.trim(),
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      role: formData.role.trim() === 'Student' ? 'user' : formData.role.trim(),
      city: formData.city.trim(),
      country: formData.country.trim()
    };

    // Frontend validation checks
    if (!trimmedFormData.firstName) formErrors.firstName = 'First name is required';
    else if (!nameRegex.test(trimmedFormData.firstName)) formErrors.firstName = 'First name must only contain letters, spaces, or hyphens';

    if (!trimmedFormData.lastName) formErrors.lastName = 'Last name is required';
    else if (!nameRegex.test(trimmedFormData.lastName)) formErrors.lastName = 'Last name must only contain letters, spaces, or hyphens';

    if (!trimmedFormData.username) formErrors.username = 'Username is required';

    if (!trimmedFormData.email) formErrors.email = 'Email is required';
    else if (!emailRegex.test(trimmedFormData.email)) formErrors.email = 'Email must be a valid email address';

    if (!trimmedFormData.password) formErrors.password = 'Password is required';
    else if (trimmedFormData.password.length < 8) formErrors.password = 'Password must be at least 8 characters long';
    if (trimmedFormData.password !== trimmedFormData.confirmPassword) formErrors.confirmPassword = 'Passwords do not match';

    if (!trimmedFormData.role) formErrors.role = 'Role is required';
    if (!trimmedFormData.city) formErrors.city = 'City is required';
    if (!trimmedFormData.country) formErrors.country = 'Country is required';

    // If there are form errors, prevent submission and display them
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/register`, trimmedFormData);

      if (response.status === 201) {
        navigate('/auth/verification');
      } else {
        setErrors({ general: 'Signup failed' });
      }
    } catch (error) {
      if (error.response?.data?.msg === "Email already in use") {
        setErrors({ email: "Email already in use" });
      } else if (error.response?.data?.msg === "Username already in use") {
        setErrors({ username: "Username already in use" });
      } else {
        setErrors({ general: error.response?.data?.msg || 'Network error' });
      }
    }
  };

  // Checkbox state handler
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          <div className="mt-[3vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
              Sign Up
            </h3>
            <p className="mt-[10px] ml-1 text-base text-gray-600 dark:text-gray-300">
              Enter your email and password to sign up!
            </p>
            {errors.general && <p className="text-red-500">{errors.general}</p>}
            <div className="mt-4 mb-4 flex items-center gap-3">
              <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
              <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
            </div>
            <div className="mb-3 flex w-full items-center justify-center gap-4">
              <div className="w-1/2">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="First Name*"
                  placeholder="John"
                  id="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  state={errors.firstName ? 'error' : ''}
                />
                {errors.firstName && <p className="text-red-500 text-xs">{errors.firstName}</p>}
              </div>

              <div className="w-1/2">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Last Name*"
                  placeholder="Doe"
                  id="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  state={errors.lastName ? 'error' : ''}
                />
                {errors.lastName && <p className="text-red-500 text-xs">{errors.lastName}</p>}
              </div>
            </div>
            <InputField
              variant="auth"
              extra="mb-3"
              label="Username*"
              placeholder="johndoe123"
              id="username"
              type="text"
              value={formData.username}
              onChange={handleChange}
              state={errors.username ? 'error' : ''}
            />
            {errors.username && <p className="text-red-500 text-xs">{errors.username}</p>}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder="mail@simmmple.com"
              id="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              state={errors.email ? 'error' : ''}
            />
            {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
            {/* Dropdown for Role */}
            <DropdownForForm
              label="Role*"
              options={['Student', 'Mentor']}
              selectedOption={formData.role}
              onSelect={(value) => {
                setFormData({ ...formData, role: value });
                setErrors(prevErrors => ({ ...prevErrors, role: '' }));
              }}
              variant="auth"
              state={errors.role ? 'error' : ''}
              error={errors.role}
            />

            {/* Dropdown for Country */}
            <DropdownForForm
              label="Country*"
              options={['Kenya', 'Somalia']}
              selectedOption={formData.country}
              onSelect={(value) => {
                setFormData({ ...formData, country: value });
                setErrors(prevErrors => ({ ...prevErrors, country: '' }));
              }}
              variant="auth"
              state={errors.country ? 'error' : ''}
              error={errors.country}
            />


            {/* New City and Country Input Fields */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="City*"
              placeholder="e.g., Nairobi"
              id="city"
              type="text"
              value={formData.city}
              onChange={handleChange}
              state={errors.city ? 'error' : ''}
            />
            {errors.city && <p className="text-red-500 text-xs">{errors.city}</p>}
          
            
            <InputField
              variant="auth"
              extra="mb-3 relative"
              label="Password*"
              placeholder="Min 8 characters"
              id="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              state={errors.password ? 'error' : ''}
              icon = {showPassword ? <FaEye /> : <FaEyeSlash />}
              onIconClick = {toggleShowPassword}
            />
            {errors.password && <p className="text-red-500 text-xs">{errors.password}</p>}
            <InputField
              variant="auth"
              extra="mb-3 relative"
              label="Confirm Password*"
              placeholder="Min 8 characters"
              id="confirmPassword"
              type={showPassword ? "text" : "password"}
              value={formData.confirmPassword}
              onChange={handleChange}
              state={errors.confirmPassword ? 'error' : ''}
              icon={showPassword ? <FaEye /> : <FaEyeSlash />}
              onIconClick={toggleShowPassword}
            />
            {errors.confirmPassword && <p className="text-red-500 text-xs">{errors.confirmPassword}</p>}
            <div className="mt-4 flex items-center justify-between px-2">
            <div className="flex">
              <Checkbox id="checkbox" color="orange" onChange={handleCheckboxChange} checked={isChecked} />
              <label htmlFor="checkbox" className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white">
                By creating an account, you agree to the{" "}
                <Link to="/auth/terms-of-service" className="text-orange-400 hover:text-brand-900">
                  Terms and Conditions
                </Link>
                {" "}and our{" "}
                <Link to="/auth/privacy-policy" className="text-orange-400 hover:text-brand-900">
                  Privacy Policy
                </Link>.
              </label>
            </div>
            </div>
              <button
                className={`mt-4 w-full rounded-xl py-3 text-base font-medium text-white transition duration-200 ${
                  isChecked && Object.values(formData).every(field => field) // Check if all fields are filled
                    ? 'bg-orange-500 hover:bg-brand-900 active:bg-dark dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200'
                    : 'bg-gray-500 cursor-not-allowed'
                }`}
                onClick={handleSignUp}
                disabled={!isChecked || !Object.values(formData).every(field => field)} // Disable if not checked or form incomplete
              >
                Create my account
              </button>
            <div className="mt-3">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                Already a member?
              </span>
              <a
                href="/auth/sign-in/"
                className="ml-1 text-sm font-medium text-orange-400 hover:text-brand-900 dark:text-white"
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SignUpDefault;
