import React from "react";

const SingleMessage = ({
  message,
  time,
  isSent,
  senderName,
  senderImageUrl,
  isTeamChat,
  isSenderMentor,
  isLatest, // New prop
}) => {
  const messageClasses = isSent
    ? "bg-orange-500 text-white rounded-l-xl rounded-br-xl dark:bg-orange-700"
    : "bg-navy-600 text-white rounded-r-xl rounded-bl-xl dark:bg-gray-700 dark:text-white";

  const timeClasses = isSent
    ? "text-white dark:text-gray-300"
    : "text-gray-500 dark:text-gray-300";

  const formattedTime = new Date(time).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div
      className={`mb-3 flex ${isSent ? "justify-end" : "justify-start"} ${
        isLatest ? "bg-yellow-100 dark:bg-yellow-800 p-2 rounded-lg" : ""
      }`}
    >
      {/* Display avatar only in team chats */}
      {isTeamChat && !isSent && senderImageUrl && (
        <img
          src={senderImageUrl}
          alt={senderName}
          className="h-8 w-8 rounded-full object-cover mr-2"
        />
      )}
      <div className={`p-5 ${messageClasses} max-w-[75%]`}>
        {/* Display sender's name and mentor badge only in team chats */}
        {isTeamChat && senderName && (
          <p className="text-sm font-bold mb-3 flex items-center">
            {senderName}
            {isSenderMentor && (
              <span className="ml-2 bg-blue-500 dark:bg-blue-600 text-white text-xs font-semibold px-2 py-0.5 rounded-full flex items-center">
                <svg
                  className="w-3 h-3 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm0 18.333c-1.11 0-2.167-.217-3.125-.617a8.272 8.272 0 0 1-1.792-1.125 1 1 0 0 1-.166-1.391l.5-.5a1 1 0 0 1 1.445-.14A6.75 6.75 0 1 0 10 3.25a6.75 6.75 0 0 0-6.75 6.75A6.75 6.75 0 0 0 10 16.25a8.247 8.247 0 0 0 3.6-.858 1 1 0 0 1 .879 1.792A9.998 9.998 0 0 1 10 18.333z" />
                </svg>
                Mentor
              </span>
            )}
          </p>
        )}
        <h1 className="text-md pb-2">{message}</h1>
        <p className={`text-sm font-medium ${timeClasses}`}>{formattedTime}</p>
      </div>
    </div>
  );
};

export default SingleMessage;
