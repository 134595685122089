import { Navigate } from "react-router-dom";
import { useAuth } from "./authContext";
import { useSubscriptionStatus } from "hooks/useSubscriptionStatus";
import { Permissions } from "utils/permissions";
import Loader from "components/loaders/Loader";

const ProtectedRoute = ({
  element,
  requiredPermission,
  requiredPermissions = [],
  requiresSubscription = false,
  courseId,
}) => {
  const { isAuthenticated, user, loading } = useAuth();
  const { data: subscriptionStatus, isLoading, isError } = useSubscriptionStatus(user?.id);


  // Wait for authentication state to load
  if (loading) {
    return <Loader />;
  }

  // Redirect if not authenticated
  if (!isAuthenticated || !user) {
    console.warn("Access denied: User not authenticated");
    return <Navigate to="/auth/sign-in" replace />;
  }

  // Check for single permission
  if (requiredPermission && !user.permissions.includes(requiredPermission)) {
    console.warn("Access denied: Missing required permission", requiredPermission);
    return <Navigate to="/auth/sign-in" replace />;
  }

  // Check for multiple permissions
  if (
    requiredPermissions.length > 0 &&
    !requiredPermissions.some((permission) => user.permissions.includes(permission))
  ) {
    console.warn("Access denied: Missing one of the required permissions", requiredPermissions);
    return <Navigate to="/auth/sign-in" replace />;
  }

  // Check subscription status if required
  if (requiresSubscription) {
    if (isLoading) {
      return <div>Loading subscription status...</div>;
    }

    if (isError || !subscriptionStatus) {
      console.error("Subscription check failed");
      return <Navigate to="/admin/courses" replace />;
    }

    const hasActiveSubscription = subscriptionStatus.some(
      (sub) => sub.id === courseId && sub.status === "active"
    );

    if (!hasActiveSubscription) {
      console.warn("Access denied: Active subscription required");
      return <Navigate to="/admin/courses" replace />;
    }
  }

  // Render the protected component
  return element;
};

export default ProtectedRoute;
