// SearchTableOrders.js

import React, { useMemo, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useNavigate } from 'react-router-dom';
import PaymentConfirmation from 'components/modal/PayementConfirmation';

function SearchTableOrders(props) {
  const { columnsData, tableData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const navigate = useNavigate();

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  const handleEditOrder = (orderId, userId) => {
    navigate(`/admin/main/ecommerce/order-details/${orderId}`, {
      state: { userId },
    });
  };

  const handleConfirmPayment = (subscriptionId) => {
    setSelectedSubscriptionId(subscriptionId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSubscriptionId(null);
  };

  return (
    <div className="h-full w-full">
      {/* Payment Confirmation Modal */}
      <PaymentConfirmation
        isOpen={isModalOpen}
        onClose={closeModal}
        subscriptionId={selectedSubscriptionId}
      />

      {/* Search */}
      <div className="flex w-3/4 items-center rounded-[10px] bg-white p-[8px] pt-[18px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:ml-3 md:w-[400px]">
        <div className="flex h-9 w-[400px] flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
      </div>

      {/* Table */}
      <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="items-center border-b border-gray-200 dark:border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    key={columnIndex}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-4 pb-2 text-xs text-gray-600 text-start"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="w-full" {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="border-b border-gray-200 dark:border-white/10"
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'PRODUCT NAME') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'EMAIL') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'DATE') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'STATUS ORDER') {
                      data = (
                        <div
                          className={`flex h-7 w-[110px] items-center justify-center text-sm ${
                            cell.value === 'Completed'
                              ? 'bg-green-100 dark:bg-green-50'
                              : 'bg-red-100 dark:bg-red-50'
                          } rounded-[10px] font-bold`}
                        >
                          <div
                            className={`${
                              cell.value === 'Completed'
                                ? 'text-green-500'
                                : 'text-red-500'
                            } uppercase`}
                          >
                            {cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'TOTAL PRICE') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'ORDER ACTIONS') {
                      data = (
                        <div className="flex space-x-2">
                          <span
                            className="text-sm font-medium text-brand-500 underline hover:cursor-pointer dark:text-white"
                            onClick={() => handleEditOrder(row.original.id, row.original.userId)}
                          >
                            View User
                          </span>
                          <span
                            className="text-sm font-medium text-brand-500 underline hover:cursor-pointer dark:text-white"
                            onClick={() => handleConfirmPayment(row.original.id)}
                          >
                            Confirm Payment
                          </span>
                        </div>
                      );
                    }
                    return (
                      <td
                        className="px-4 py-4"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
        <div className="text-sm text-gray-700">
          Showing {pageSize * pageIndex + 1} to{' '}
          {pageSize * (pageIndex + 1) <= data.length
            ? pageSize * (pageIndex + 1)
            : data.length}{' '}
          of {data.length} entries
        </div>

        {/* Pagination Buttons (Implement as needed) */}
        <div className="flex space-x-2">
          {/* Placeholder for pagination controls */}
        </div>
      </div>
    </div>
  );
}

export default SearchTableOrders;
