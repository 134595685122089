import React, { useEffect, useRef, useState, useMemo } from "react";
import Card from "components/card";
import { BiSearch } from "react-icons/bi";
import CardMenu from "components/card/CardMenu";
import EmojiPicker from "emoji-picker-react";
import SingleMessage from "./SingleMessage";
import {
  MdChevronLeft,
  MdImage,
  MdOutlineAttachment,
  MdOutlineTagFaces,
} from "react-icons/md";
import {
  renderTrackMessages,
  renderThumbMessages,
  renderViewMessages,
} from "components/scrollbar/Scrollbar";
import { IoPaperPlane } from "react-icons/io5";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FaUsers } from "react-icons/fa";
import {
  createMessage,
  createTeamMessage,
  fetchTeamMessages,
  markMessagesAsRead,
} from "api";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";

const SingleChat = ({
  open,
  onClose,
  chatEntity,
  chatType,
  creatingNewChat,
  userId,
  userProfile,
  updateChatsWithUnread, // New prop
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const scrollRef = useRef(null);

  const queryClient = useQueryClient();

  // Fetch messages based on chat type
  const { data: messagesData, error: messagesError } = useQuery({
    queryKey:
      chatType === "team"
        ? ["teamMessages", chatEntity.id]
        : ["messages", userId],
    queryFn: async () => {
      if (chatType === "team") {
        return await fetchTeamMessages(chatEntity.id);
      } else {
        return queryClient.getQueryData(["messages", userId]) || [];
      }
    },
    enabled: !!chatEntity,
    refetchInterval: 5000, // Add refetchInterval to keep messages updated
  });

  useEffect(() => {
    if (messagesError) {
      console.error("Error fetching messages in SingleChat:", messagesError);
    }
  }, [messagesError]);

  // Filter messages for the selected chat entity
  const messages = useMemo(() => {
    let msgs = [];
    if (chatType === "team") {
      msgs = messagesData || [];
    } else {
      msgs = messagesData.filter(
        (message) =>
          (message.sender.id === chatEntity.id &&
            message.receiver.id === userProfile.id) ||
          (message.receiver.id === chatEntity.id &&
            message.sender.id === userProfile.id)
      );
    }
    // Ensure messages are sorted by timestamp
    return msgs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }, [chatType, messagesData, chatEntity.id, userProfile.id]);

  useEffect(() => {
    setFilteredMessages(messages);
  }, [messages]);

  useEffect(() => {
    if (chatEntity) {
      // Call function to mark messages as read
      markChatMessagesAsRead();
    }
  }, [chatEntity]);

  const markChatMessagesAsRead = async () => {
    try {
      if (chatType === "team") {
        // Mark team messages as read
        await markMessagesAsRead({
          userId: userId,
          teamId: chatEntity.id,
        });
      } else {
        // Mark individual messages as read
        await markMessagesAsRead({
          userId: userId,
          senderId: chatEntity.id,
        });
      }

      // Update messages in the cache
      queryClient.invalidateQueries(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId]
      );

      // Update chatsWithUnread state in Messages component
      if (updateChatsWithUnread) {
        const chatId = chatType === "team" ? chatEntity.id : chatEntity.id;
        updateChatsWithUnread(chatId);
      }
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
        scrollRef.current.scrollToBottom();
      }
  };

  useEffect(() => {
      if (chatEntity) {
          scrollToBottom();
      }
  }, [chatEntity]);

  // Mutation for sending a new message
  const mutation = useMutation({
    mutationFn: chatType === "team" ? createTeamMessage : createMessage,
    onMutate: async (newMessageData) => {
      await queryClient.cancelQueries(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId]
      );

      const previousMessages = queryClient.getQueryData(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId]
      );

      queryClient.setQueryData(
        chatType === "team"
            ? ["teamMessages", chatEntity.id]
            : ["messages", userId],
        (old) => {
            return old
                ? [
                    ...old,
                    {
                        ...newMessageData,
                        id: Date.now(), // Temporary ID
                        timestamp: new Date().toISOString(),
                        sender: { id: userId },
                        ...(chatType === "team"
                            ? {}
                            : { receiver: { id: chatEntity.id } }),
                    },
                ]
                : [
                    {
                        ...newMessageData,
                        id: Date.now(),
                        timestamp: new Date().toISOString(),
                        sender: { id: userId },
                        ...(chatType === "team"
                            ? {}
                            : { receiver: { id: chatEntity.id } }),
                    },
                ];
        }
    );
    

      return { previousMessages };
    },
    onError: (err, newMessageData, context) => {
      queryClient.setQueryData(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId],
        context.previousMessages
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        chatType === "team"
          ? ["teamMessages", chatEntity.id]
          : ["messages", userId]
      );
    },
  });

  const handleEmojiClick = (emoji) => {
    setNewMessage((prevMessage) => prevMessage + emoji.emoji);
    setShowEmojiPicker(false); // Close picker after selection
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "model/obj" ||
        file.type === "model/stl" ||
        file.type.startsWith("image"))
    ) {
      setSelectedFile(file);
    } else {
      alert("Please select a valid OBJ, STL, or image file.");
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === "" || !chatEntity) return;
  
    if (chatType === "team") {
      // Send message to team
      const messageData = {
        senderId: userId,
        teamId: chatEntity.id,
        content: newMessage,
      };
  
      mutation.mutate(messageData);
    } else {
      // Send individual message
      const isMentorMessagingAllowed =
        userProfile.role === "mentor" &&
        chatEntity.schoolId === userProfile.schoolId;
      const isUserMessagingAllowed = chatEntity.teamId === userProfile.teamId;
  
      if (isMentorMessagingAllowed || isUserMessagingAllowed) {
        const messageData = {
          senderId: userId,
          receiverId: chatEntity.id,
          content: newMessage,
        };
  
        mutation.mutate(messageData);
      } else {
        console.error("You are not allowed to message this user.");
        return;
      }
    }
  
    setNewMessage("");
    // Scroll to bottom after sending a message
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const chatName =
    chatType === "team"
      ? chatEntity.name
      : `${chatEntity.firstName} ${chatEntity.lastName}`;
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const chatImageUrl =
    chatType === "team"
      ? null
      : chatEntity.imageUrl
      ? `${baseURL}${chatEntity.imageUrl}`
      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

  const handleSearchMessages = () => {
    if (searchTerm.trim() === "") {
      setFilteredMessages(messages); // Reset to original messages
    } else {
      const filtered = messages.filter((msg) =>
        msg.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMessages(filtered);
    }
  };
      

  return (
    <div
      className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-[calc(100vh-40px)] w-[calc(100vw-40px)] flex-col !font-dm transition-all md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${
        open
          ? "translate-x-[20px] lg:translate-x-[0px]"
          : "-translate-x-[120%] lg:translate-x-[0px]"
      }`}
    >
      <Card extra={"w-full h-full p-4 !font-dm"}>
        {/* Header */}
        <div className="mb-auto flex flex-col lg:flex-row items-center justify-between border-b border-gray-200 pb-3 dark:border-navy-700">
          <div className="flex items-center gap-3 w-full lg:w-auto">
            <div className="flex items-center justify-center gap-2">
              <div onClick={onClose} className={"block lg:hidden"}>
                <MdChevronLeft className="h-5 w-5 text-gray-500 dark:text-white" />
              </div>
              <div className="h-[50px] w-[50px] rounded-full flex items-center justify-center bg-gray-300 dark:bg-gray-700">
                {chatType === "team" ? (
                  <FaUsers className="h-6 w-6 text-white" />
                ) : (
                  <img
                    src={chatImageUrl}
                    className="h-full w-full rounded-full object-cover"
                    alt={chatName}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col items-center lg:items-start">
              <h1 className="font-dm text-[18px] sm:text-[20px] font-bold text-navy-700 dark:text-white">
                {creatingNewChat ? "Create New Chat" : chatName}
                {chatType === "team" && (
                  <span className="ml-2 bg-gradient-to-r from-green-400 to-green-600 text-white text-xs px-2 py-1 rounded-full">
                    Team
                  </span>
                )}
                {chatEntity.role === "mentor" && chatType !== "team" && (
                  <span className="ml-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white text-xs px-2 py-1 rounded-full">
                    Mentor
                  </span>
                )}
              </h1>
              {!creatingNewChat && chatType !== "team" && (
                <div className="flex items-center gap-2">
                  <div
                    className={`h-3 w-3 rounded-full border-2 border-white ${
                      chatEntity.status === "Online"
                        ? "bg-green-500"
                        : "bg-red-500"
                    }`}
                  ></div>
                  <h4 className="text-base text-navy-700 dark:text-white">
                    {chatEntity.status || "Offline"}
                  </h4>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2 mt-3 lg:mt-0 w-full lg:w-auto">
            <input
              type="text"
              placeholder="Search messages..."
              className="block h-10 w-full lg:w-40 px-4 rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-gray-100 dark:py-2 dark:px-4"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <BiSearch
              className="h-5 w-5 text-dark dark:text-white hover:cursor-pointer"
              onClick={handleSearchMessages}
            />
            <CardMenu vertical />
          </div>
        </div>
        {/* Message Content */}
        <div className="relative h-[calc(100%-70px)] pt-2 overflow-y-auto scrollbar-hide">
          <div className="flex h-[calc(100%-74px)] w-full">
            <Scrollbars
              renderTrackVertical={renderTrackMessages}
              renderThumbVertical={renderThumbMessages}
              renderView={renderViewMessages}
              ref={scrollRef}
            >
              <div className="relative max-h-max overflow-hidden pb-[30px] lg:max-h-[unset] lg:overflow-visible flex flex-col">
                {filteredMessages.map((msg, index) => {
                  const isSent = msg.sender.id === userId;
                  const isTeamChat = chatType === "team";

                  // Only set senderName and senderImageUrl for team chats
                  const senderName =
                    isTeamChat && !isSent
                      ? `${msg.sender.firstName} ${msg.sender.lastName}`
                      : null;
                  const senderImageUrl =
                    isTeamChat && !isSent
                      ? msg.sender.imageUrl
                        ? `${process.env.REACT_APP_API_BASE_URL}${msg.sender.imageUrl}`
                        : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                      : null;

                  // Determine if sender is a mentor
                  const isSenderMentor =
                    isTeamChat && msg.sender.role === "mentor";

                  return (
                    <SingleMessage
                      key={index}
                      message={msg.content}
                      time={msg.timestamp}
                      isSent={isSent}
                      senderName={senderName}
                      senderImageUrl={senderImageUrl}
                      isTeamChat={isTeamChat}
                      isSenderMentor={isSenderMentor} // Pass down if sender is mento
                    />
                  );
                })}
              </div>
            </Scrollbars>
          </div>
          {/* Message Input */}
          <div className="mt-6 flex items-center gap-2">
            <div className="relative flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">
              {/* Emoji Picker Toggle */}
              <p
                className="text-lightSecondary pl-3 text-xl hover:cursor-pointer"
                onClick={() => setShowEmojiPicker((prev) => !prev)}
              >
                <MdOutlineTagFaces />
              </p>

              {/* Emoji Picker Component */}
              {showEmojiPicker && (
                <div className="absolute bottom-14 left-0 z-50">
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
              )}

              {/* Message Input */}
              <input
                placeholder="Write your message..."
                className="h-full w-full rounded-full bg-lightPrimary px-2 text-sm text-navy-700 outline-none dark:bg-navy-700 dark:text-white"
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSendMessage();
                }}
              />

              {/* File Inputs */}
              <div className="flex items-center gap-4">
                {/* File Upload */}
                <label className="text-lightSecondary text-xl hover:cursor-pointer">
                  <MdOutlineAttachment />
                  <input
                    type="file"
                    accept=".obj,.stl"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                </label>

                {/* Image Upload */}
                <label className="text-lightSecondary pr-2 text-xl hover:cursor-pointer">
                  <MdImage />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
            </div>

            {/* Send Button */}
            <button
              className="linear flex items-center justify-center rounded-full bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300"
              onClick={handleSendMessage}
            >
              <IoPaperPlane />
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SingleChat;
