import Card from "components/card";

const CourseCard = ({ title, author, image, extra, href }) => {
  const handleCardClick = () => {
    window.open(href, "_blank"); // Open the course link in a new tab
  };

  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-[18px] bg-white dark:bg-navy-700 ${extra}`}
    >
      <div className="h-full w-full">
        {/* Clickable card */}
        <div
          className="relative w-full h-[200px] mb-4 cursor-pointer group"
          onClick={handleCardClick}
        >
          <img
            src={image}
            className="h-full w-full rounded-xl object-cover transition-transform transform group-hover:scale-105 group-hover:brightness-110"
            alt={title}
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 rounded-xl transition-opacity group-hover:bg-opacity-20" />
        </div>

        {/* Course Information */}
        <div className="flex items-start px-1">
          <div className="mb-2">
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {title}
            </p>
            <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
              {author}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CourseCard;
