import Card from "components/card";
import Transaction from "components/dataDisplay/Transaction";
import { MdOutlineSchool } from "react-icons/md";

// Helper function to format the date
const formatDate = (date) => {
  const options = { day: "2-digit", month: "long", year: "numeric" };
  return new Date(date).toLocaleDateString("en-GB", options);
};

const YourTransaction = ({ transactions }) => {
  console.log("Transactions data in YourTransaction:", transactions);
  return (
    <Card extra="w-full h-full p-3 flex flex-col justify-between">
      <h4 className="mt-[20px] ml-[15px] text-lg font-bold text-navy-700 dark:text-white pt-3">
        Your Transactions
      </h4>

      <div className="flex-grow mt-[26px] px-3">
        {transactions.length === 0 ? (
          // Centered message only when there are no transactions
          <div className="flex items-center justify-center h-full">
            <p className="text-center text-gray-500 dark:text-white">
              No transactions found.
            </p>
          </div>
        ) : (
          // List of transactions
          transactions.map((transaction) => (
            <Transaction
              key={transaction.id}
              title={transaction.courseTitle}
              date={formatDate(transaction.paymentDate)}
              sum={`-Kshs ${transaction.totalPaid}`}
              status={transaction.status}
              mb="mb-[30px]"
              icon={<MdOutlineSchool className="text-brand-500 dark:text-white" />}
            />
          ))
        )}
      </div>

      {/* "See all transactions" link at the bottom */}
      <div className="ml-auto mt-[42px] mb-[10px] flex w-max items-center gap-1">
        <div className="text-base font-bold text-brand-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          See all transactions
        </div>
      </div>
    </Card>
  );
};

export default YourTransaction;
