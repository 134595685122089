import React, { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { confirmSubscriptionPayment } from 'api';

const PaymentConfirmation = ({ isOpen, onClose, subscriptionId }) => {
  const [referenceNumber, setReferenceNumber] = useState('');

  const { mutate: confirmPayment, isLoading, isError, error } = useMutation({
    mutationFn: () => confirmSubscriptionPayment(subscriptionId, referenceNumber),
    onSuccess: () => {
      alert('Payment confirmed successfully!');
      onClose();
    },
    onError: (err) => {
      alert(`Error confirming payment: ${err.message}`);
    },
  });

  const handleConfirm = () => {
    if (referenceNumber.trim()) {
      confirmPayment();
    } else {
      alert('Please enter a reference number');
    }
  };

  useEffect(() => {
    if (isOpen) {
      setReferenceNumber(''); // Reset the input when the modal opens
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-80 shadow-lg">
        <h2 className="text-xl font-bold mb-4">Confirm Payment</h2>
        <input
          type="text"
          placeholder="Enter Mpesa Reference Number"
          value={referenceNumber}
          onChange={(e) => setReferenceNumber(e.target.value)}
          className="border border-gray-300 rounded p-2 w-full mb-4"
        />
        {isError && <p className="text-red-500 mb-2">Error: {error.message}</p>}
        <button
          onClick={handleConfirm}
          disabled={isLoading}
          className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600 transition-colors duration-200"
        >
          {isLoading ? 'Confirming...' : 'Confirm Payment'}
        </button>
        <button onClick={onClose} className="mt-2 text-gray-500 w-full text-center">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
