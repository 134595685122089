import { useState } from "react";
import Card from "components/card";
import SubscriptionModal from "./SubscriptionModal";
import DescriptionModal from "./DescriptionModal"; 
import { useNavigate } from "react-router-dom";
import { IoEye } from "react-icons/io5";

const NftCard = ({ title, author, image, extra, courseId, userId, mentorId, status, onStatusUpdate, isAvailable }) => {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false); // For Description Modal
  const navigate = useNavigate();


  // Open sub modal if the status is available
  const handleSubscription = () => {
    if (status === "available") {
      setIsModalOpen(true);
    }
  }; 

  const closeModal = () => {
    setIsModalOpen(false);
    onStatusUpdate();
  };

  const handleViewCourse = () => {
    navigate(`/admin/course`); 
  };

  // Description Modal Handlers
  const openDescriptionModal = () => {
    setIsDescriptionOpen(true);
  };

  const closeDescriptionModal = () => {
    setIsDescriptionOpen(false);
  };


  // Define button properties based on status
  let buttonContent, buttonClasses, handleClick;

switch (status) {
  case "pending":
      buttonContent = (
          <div className="flex items-center gap-2">
              <svg
                  className="w-5 h-5 text-orange-500 dark:text-orange-400 animate-pulse"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 16h-1v-4h1m0 0h1v-4h-1m0 4v4m-6 4h12m-6-4v-4m0-4v-4"
                  />
              </svg>
              <span>Pending Approval</span>
          </div>
      );
      buttonClasses =
          "rounded-[20px] bg-gray-100 dark:bg-blue-800 px-4 py-2 text-base font-medium text-gray-700 dark:text-gray-300 cursor-not-allowed border border-gray-300 dark:border-gray-500 shadow-sm hover:shadow-lg transition duration-150 ease-in-out";
      handleClick = null; // Disable click for pending status
      break;
  case "active":
      buttonContent = "View Course";
      buttonClasses =
          "rounded-[20px] bg-green-600 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-500 active:bg-green-700 dark:bg-green-600 dark:hover:bg-green-400 dark:active:opacity-90";
      handleClick = handleViewCourse;
      break;
  default:
    if (isAvailable) {
      // Display "Subscribe" button if available
      buttonContent = "Subscribe";
      buttonClasses =
        "rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-900 dark:hover:bg-brand-400 dark:active:opacity-90";
      handleClick = handleSubscription;
    } else {
      // Do not display a button if not available
      buttonContent = null;
      buttonClasses = null;
      handleClick = null;
    }
}



  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-[18px] bg-white dark:bg-navy-700 ${extra}`}
    >
      <div className="h-full w-full">
        {/* Image with hover effect and onClick to open description modal */}
        <div
          className={`relative w-full h-[200px] mb-4 cursor-pointer group ${!isAvailable ? "pointer-events-none" : ""}`}
          onClick={isAvailable ? openDescriptionModal : undefined}
        >
          <img
            src={image}
            className="h-full w-full rounded-xl object-cover transition-transform transform group-hover:scale-105 group-hover:brightness-110"
            alt={title}
          />
          <div className="absolute inset-0 bg-black bg-opacity-30 rounded-xl transition-opacity group-hover:bg-opacity-40" />
          {isAvailable && (
            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
              <IoEye className="text-white text-4xl" />
            </div>
          )}
          {!isAvailable && (
            <div className="absolute top-2 right-2 bg-brand-900 dark:bg-brand-400 text-white dark:text-white px-2 py-1 rounded-md">
              Coming Soon
            </div>
          )}
        </div>

        <div className="flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-2">
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {title}
            </p>
            <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
              {author}
            </p>
          </div>
          {buttonContent && (
            <button
              className={buttonClasses}
              onClick={handleClick}
              aria-label="Course Action Button"
              disabled={status === "pending"}
            >
              {buttonContent}
            </button>
          )}
        </div>
      </div>

      {/* Subscription modal */}
      {isModalOpen && (
        <SubscriptionModal
          onClose={closeModal}
          parentName={title}
          courseId={courseId}
          userId={userId}
          mentorId={mentorId}
        />
      )}

      {/* Description modal */}
      {isDescriptionOpen && (
        <DescriptionModal
          onClose={closeDescriptionModal}
          title="3D Printing with PrintLab"
          description={`The 3D Printing course by PrintLab is a comprehensive introduction to 3D design and printing technology. This course is ideal for students looking to develop skills in CAD software such as Tinkercad and Fusion 360. Through a variety of interactive lessons and hands-on projects, students will explore the entire process of creating 3D models and printing functional designs.`}
        />
      )}
    </Card>
  );
};

export default NftCard;
