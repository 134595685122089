import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const ReceiptTable = (props) => {
  const { columnsData, tableData } = props;

  console.log("Columns Data in ReceiptTable:", columnsData);
  console.log("Table Data in ReceiptTable:", tableData);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;
  return (
    <div className="h-full w-full overflow-x-scroll py-[45px] dark:bg-navy-800 md:px-9 lg:overflow-x-hidden">
      <table {...getTableProps()} className="w-full text-gray-600">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr
              className=""
              {...headerGroup.getHeaderGroupProps()}
              key={index}
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  className="border-b border-gray-200 px-4 text-start dark:border-white/10"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                >
                  <div className="pb-[11px] text-xs font-bold uppercase text-gray-600">
                    {column.render("Header")}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody className="" {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr className="" {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  const rowData = cell.row.original;
                  const columnId = cell.column.id;

                  if (columnId === "item") {
                    data = (
                      <div className="">
                        <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                          {rowData.courseTitle || "N/A"}
                        </p>
                        <p className="font-base mt-[2px] text-gray-600">
                          Subscription Type: {rowData.subscriptionType || "N/A"}
                        </p>
                      </div>
                    );
                  } else if (columnId === "quantity") {
                    data = (
                      <div className="text-base font-bold text-navy-700 dark:text-white">
                        1
                      </div>
                    );
                  } else if (columnId === "rate") {
                    data = (
                      <div className="text-base font-bold text-navy-700 dark:text-white">
                        {rowData.currency}{" "}
                        {rowData.amount ? rowData.amount.toFixed(2) : "0.00"}
                      </div>
                    );
                  } else if (columnId === "amount") {
                    data = (
                      <div className="text-base font-bold text-navy-700 dark:text-white">
                        {rowData.currency}{" "}
                        {rowData.amount ? rowData.amount.toFixed(2) : "0.00"}
                      </div>
                    );
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={index}
                      className="font-sm mt-[20px] border-b border-gray-200 px-[23px] py-[25px] dark:border-white/10"
                    >
                      {data}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReceiptTable;
